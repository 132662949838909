<!--
 * @Author: wh
 * @Date: 2020-06-28 17:19:35
 * @LastEditTime: 2020-06-28 19:25:02
 * @LastEditors: Please set LastEditors
 * @Description: team contacts
 * @FilePath: \Web\JT.Web\src\views\messageCenter\pages\page\components\dialogue\TeamContacts.vue
-->

<template>
    <div>
        <ul>
            <li
                class="noData ng-scope"
                v-if="teamList.length<=0"
            >
                暂无联系人
            </li>
            <li
                v-for="(team, index) in teamList"
                :key="team.teamId"
                :title="team.name"
                @click="enterChat('team-'+team.teamId,team, 'team'+index)"
                :class="{'active':'team'+index === activeIndex}"
                class="team"
            >
                <div class="HeadPortrait">
                    <p>{{ team.name }}</p>
                </div>
                <div class="information">
                    <div class="infoTitle">
                        <p :title="team.name">
                            <span>{{ team.name }}</span>
                        </p>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    props: {
        contactsList: {
            type: [Array],
        },
    },
    data() {
        return {
            activeIndex: null,
        };
    },
    created() {
    },
    computed: {
        teamList: function () {
            return window.$globalHub.$store.state.teamlist && window.$globalHub.$store.state.teamlist.filter(team => {
                return team.validToCurrentUser;
            });
        },
    },
    methods: {
        // 发起聊天
        enterChat(sessionID, rowData, index) {
            this.$emit('clickContact', sessionID, rowData);
            this.activeIndex = index;
        },
    },
    filters: {
        spliceOrgName(value) {// value是输入框的内容，也是要显示的内容
            if (value) {
                let str = value.toString();
                if (str.length > 6) {
                    str = str.substr(0, 6) + '...';
                }

                return str; // 数组转字符串 以空格输出。。。
            }
        },
    },
};
</script>
<style></style>
